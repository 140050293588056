import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import View from "../components/View"
import { getParameterByName } from "../utils/url";
import ResetPassword from "../components/AuthParts/ResetPassword"
import RecoverEmail from "../components/AuthParts/RecoverEmail"
import VerifyEmail from "../components/AuthParts/VerifyEmail"
import firebase from "gatsby-plugin-firebase"
import RevertSecondFactor from "../components/AuthParts/RevertSecondFactor";

const Index = () => {
  const [ mode, setMode ] = useState(null)
  const [ actionCode, setActionCode ] = useState(null)
  const [ continueUrl, setContinueUrl ] = useState(null)
  const [ expired, setExpired ] = useState(false)

  useEffect(() => {
    const actionCode = getParameterByName("oobCode")
    setMode(getParameterByName("mode"))
    setActionCode(actionCode)
    setContinueUrl(getParameterByName("continueUrl"))

    if (actionCode) {
      firebase.auth().checkActionCode(actionCode).then((value) => {
        console.log(value)
        setExpired(false)
      }).catch(() => {
        setExpired(true)
      })
    }
  }, [])

  const renderContents = () => {
    let retVal = null
    if (mode && actionCode) {
      if (expired) {
        retVal = <View title={"ERROR"}>
          <p className="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
            URLの有効期限が切れているか、その処理は既に完了済みです。
          </p>
        </View>
      } else {
        switch (mode) {
          case "revertSecondFactorAddition":
            retVal = <RevertSecondFactor actionCode={actionCode} />
            break
          case "resetPassword":
            retVal = <ResetPassword actionCode={actionCode} continueUrl={continueUrl}/>
            break
          case "recoverEmail":
            retVal = <RecoverEmail actionCode={actionCode} continueUrl={continueUrl}/>
            break
          case "verifyEmail":
            retVal = <VerifyEmail actionCode={actionCode} continueUrl={continueUrl}/>
            break
          default:
            retVal = <View title={"ERROR"}>
              <p className="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
                データが不正です。
              </p>
            </View>
        }
      }
    } else {
      retVal = <View title={"ERROR"}>
        <p className="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
          データが不正です。
        </p>
      </View>
    }

    return retVal
  }

  return (
    <Layout>
      {renderContents()}
    </Layout>
  )
}

export default Index
