import React, { useEffect, useState } from "react"
import View from "../View"
import { useFormik } from "formik"
import * as Yup from 'yup'
import firebase from "gatsby-plugin-firebase"

const ResetPassword = (props) => {
  const { actionCode, continueUrl } = props
  const [ loading, setLoading ] = useState(true)
  const [ email, setEmail ] = useState(null)
  const [ submitting , setSubmitting ] = useState(false)
  const [ complete, setComplete ] = useState(false)

  useEffect(() => {
    firebase.auth().verifyPasswordResetCode(actionCode).then(email => {
      setEmail(email)
      setLoading(false)
    }).catch(e => {
      console.log(e)
      setEmail(null)
      setLoading(false)
    })
  }, [actionCode])

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("パスワードは必須です")
      .matches(/^[0-9a-zA-Z]+$/, '半角英数字のみを入力してください')
      .min(8, "パスワードが短すぎます8文字以上を指定してください"),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], "パスワードが一致しません")
  })
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema,
    onSubmit: values => {
      setSubmitting(true)
      firebase.auth().confirmPasswordReset(actionCode, values.password).then(res => {
        setSubmitting(false)
        if (continueUrl) {
          setComplete(true)
          window.location.replace(continueUrl)
        } else {
          setComplete(true)
        }
      }).catch(e => {
        console.log(e)
        setSubmitting(false)
        alert("エラーが発生しました。パスワードを変更するか、再度パスワードリセット申請を行ってください。")
      })
    }
  })

  const renderLoading = () => {
    return (
      <View title={"Now loading..."}>
      </View>
    )
  }

  const renderInputForm = () => {
    let retVal = null

    if (email) {
      if (complete) {
        retVal = (
          <View title={`処理完了`}>
            <p class="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
              パスワード変更が完了しました。
            </p>
          </View>
        )
      } else {
        retVal = (
          <View title={`${email}のパスワードリセット`}>
            <p class="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
              パスワードのリセットが申請されました。<br />
              下記のフォームに8桁以上の英数字で新たなパスワードを入力してください。
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="relative flex w-full flex-wrap items-stretch mb-3">
          <span
            className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i className="fas fa-lock"></i>
          </span>
                <input
                  type="password"
                  name={"password"}
                  placeholder="新しいパスワードを入力"
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-5"
                  {...formik.getFieldProps("password")}
                />
                {formik.errors.password ?
                  <span
                    className="text-sm font-light leading-relaxed mt-0 mb-4 ml-3 text-red-800">{formik.errors.password}</span> : null}
              </div>
              <div className="relative flex w-full flex-wrap items-stretch mb-3">
          <span
            className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i className="fas fa-lock"></i>
          </span>
                <input
                  type="password"
                  name={"passwordConfirm"}
                  placeholder="確認用パスワード"
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-5"
                  {...formik.getFieldProps("passwordConfirm")}
                />
                {formik.errors.passwordConfirm ?
                  <span
                    className="text-sm font-light leading-relaxed mt-0 mb-4 ml-3 text-red-800">{formik.errors.passwordConfirm}</span> : null}
              </div>
              <button type={"submit"}
                      className={"bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline float-right"}>
                {submitting ? "送信中..." : "送信"}
              </button>
            </form>
          </View>
        )
      }
    } else {
      retVal = (
        <View title={"ERROR"}>
          <p className="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
            エラーが発生しました<br/>
            パスワードリセットを再申請してください。
          </p>
        </View>
      )
    }

    return retVal
  }


  return loading ? renderLoading() : renderInputForm()
}

export default ResetPassword
