import React, { useState } from "react"
import View from "../View"
import firebase from "gatsby-plugin-firebase"

const VerifyEmail = (props) => {
  const { actionCode, continueUrl } = props
  const [ submitting, setSubmitting ] = useState(false)
  const [ complete, setComplete ] = useState(false)

  const applyActionCode = () => {
    setSubmitting(true)
    firebase.auth().applyActionCode(actionCode).then(() => {
      setSubmitting(false)
      if (continueUrl) {
        setComplete(true)
        window.location.replace(continueUrl)
      } else {
        setComplete(true)
      }
    }).catch(e => {
      console.log(e)
      setSubmitting(false)
      alert("エラーが発生しました。\n時間を空けて再度実施していただくか、管理者へご連絡ください。")
    })
  }

  return complete ?
    <View title={`処理完了`}>
      <p class="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
        メールアドレスの確認が完了しました。
      </p>
    </View>
    :
    <View title={`メールアドレスの確認`}>
      <p class="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
        メールアドレスの確認が必要です。<br />
        下記のボタンを押して処理を続行してください。
      </p>
      <button type={"button"} onClick={applyActionCode}
              className={"bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline float-right"}>
        {submitting ? "送信中..." : "確認"}
      </button>
    </View>

}

export default VerifyEmail
