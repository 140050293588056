import React, { useEffect, useState } from "react"
import View from "../View"
import firebase from "gatsby-plugin-firebase"

const RevertSecondFactor = (props) => {
  const { actionCode } = props
  const [ loading, setLoading ] = useState(true)
  const [ phoneNumber, setPhoneNumber ] = useState(null)
  const [ submitting, setSubmitting ] = useState(false)
  const [ complete, setComplete ] = useState(false)
  const [ email, setEmail ] = useState(null)

  useEffect(() => {
    firebase.auth().checkActionCode(actionCode).then(info => {
      const mfaInfo = info["data"]["multiFactorInfo"]
      const phoneNumber = mfaInfo["phoneNumber"]
      const email = info["data"]["email"]
      setEmail(email)
      setPhoneNumber(phoneNumber)
      setLoading(false)
    }).catch(e => {
      console.log(e)
      setLoading(false)
    })
  }, [actionCode])

  const applyActionCode = () => {
    setSubmitting(true)
    firebase.auth().applyActionCode(actionCode).then(() => {
      return firebase.app().functions("asia-northeast1").httpsCallable("sync_multi_factor")({ email }).then(() => {
        setComplete(true)
      })
    }).catch(e => {
      console.log(e)
      setSubmitting(false)
      alert("エラーが発生しました。\n時間を空けて再度実施していただくか、管理者へご連絡ください。")
    })
  }

  const renderLoading = () => {
    return (
      <View title={"Now loading..."}>
      </View>
    )
  }

  const renderInputForm = () => {
    let retVal = null

    if (phoneNumber) {
      if (complete) {
        retVal = (
          <View title={`処理完了`}>
            <p class="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
              2段階認証を取り消しました。
            </p>
          </View>
        )
      } else {
        retVal = (
          <View title={`2段階認証の取り消し`}>
            <p class="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
              2段階認証の登録を取り消すことが可能です。<br />
              直近で登録された2段階認証を取り消す場合は下記のボタンを押下してください。
            </p>
            <button type={"button"} onClick={applyActionCode}
                    className={"bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline float-right"}>
              {submitting ? "送信中..." : "2段階認証を取り消す"}
            </button>
          </View>
        )
      }
    } else {
      retVal = (
        <View title={"ERROR"}>
          <p className="text-base font-light leading-relaxed mt-6 mb-4 text-gray-600">
            エラーが発生しました。<br/>
            時間を空けて再度実施してください。
          </p>
        </View>
      )
    }

    return retVal
  }


  return loading ? renderLoading() : renderInputForm()
}

export default RevertSecondFactor
